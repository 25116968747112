import * as React from 'react'
import Layout from '../components/layout'

const PriorityGov = () => {
    return (
        <Layout pageTitle='Prioritization' nextBottomSlideBtn="Graphic: Govenerment" nextBottomSlideBtnLink="/GraphicGov">
            <div className="fullWidth"> 
                <div>
                    <h2>Identify the highest impact opportunities</h2>
                    <p>With so many options, finding the right projects to start with can be complex. Southern 360 helps you prioritize projects that optimize your energy use and advance your sustainability and resiliency goals. By leveraging your data to produce insights around energy efficiency, renewable and storage options, and electric vehicles, this step enables you to move forward confidently with implementing your carbon reduction strategy.</p>
                </div>
            </div>
        </Layout>
    )
}

export default PriorityGov